import { sidebarTooltipStyles } from "@utils/styleStrings";
import clsx from "clsx";
import { Spinner, Tooltip } from "components/shared";
import { getCornerTriangle, getSidebarCover } from "./helpers";

type Props = {
  bgColor?: string;
  liveCount: number;
  className?: string;
  isLoading?: boolean;
  tooltipText: string;
};

export const LiveCountBadge = ({
  bgColor,
  liveCount,
  className,
  isLoading,
  tooltipText,
}: Props) => (
  <Tooltip
    className={clsx(
      "absolute flex items-center justify-end right-0 top-0 w-fit h-full -mx-2 ",
      className
    )}
    tooltipProps={sidebarTooltipStyles}
    content={
      <div className="text-center w-fit max-w-[150px] text-sm font-semibold leading-[18px]">
        {tooltipText}
      </div>
    }
  >
    <div className="flex flex-center w-fit h-full relative overflow-hidden">
      {isLoading ? (
        <Spinner className="mr-[10px]" />
      ) : (
        <>
          <div
            style={{ borderColor: bgColor }}
            className={clsx(
              "flex flex-center h-fit rounded-full border-[9px] z-[30]",
              liveCount > 0 ? "p-[2px]" : "p-px"
            )}
          >
            <p
              className={clsx(
                "flex flex-center z-20 min-w-[22px] rounded-full px-[4px] py-[4px] leading-none",
                liveCount > 0 ? "text-white" : "text-slate-400"
              )}
              style={{ backgroundColor: bgColor }}
            >
              {liveCount}
            </p>
          </div>

          {getSidebarCover(true, bgColor)}
          {getSidebarCover(false, bgColor)}

          {getCornerTriangle(true, true, bgColor)}
          {getCornerTriangle(true, false, bgColor)}
          {getCornerTriangle(false, true, bgColor)}
          {getCornerTriangle(false, false, bgColor)}

          <div className="absolute flex flex-center h-full w-full z-10 overflow-hidden">
            <div
              className={
                liveCount > 0
                  ? "liveButtonBg"
                  : "absolute !bg-slate-500 w-full h-full"
              }
            />
          </div>
        </>
      )}
    </div>
  </Tooltip>
);
