import { Routes } from "@utils/routes";
import { getSpoofUser } from "@utils/withFragments/spoof";
import clsx from "clsx";
import { Icon } from "components/shared/Icon";
import { useAuth } from "contexts/AuthProvider";

export const SpoofPanel = () => {
  const spoofUser = getSpoofUser();
  const { logoutAsSpoofUser } = useAuth();

  return spoofUser != null ? (
    <div
      onClick={() => logoutAsSpoofUser(Routes.users)}
      className={clsx(
        "relative flex flex-center h-9 gap-3 border border-gray-500 px-2 mx-2 rounded-md",
        "hover:bg-rose-900 hover:border-rose-500 cursor-pointer bg-gray-600 text-white group"
      )}
    >
      <div className="flex items-center h-full justify-center">
        <Icon icon="ghost" size={5} color="text-violet-400" />
      </div>
      <span className="text-base whitespace-nowrap">{spoofUser?.fullName}</span>
      <Icon
        icon="remove"
        size={4}
        color="text-rose-400 group-hover:text-rose-500"
      />
    </div>
  ) : null;
};
